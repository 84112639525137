<template>
    <div>
        <h2>ขาย > เครื่อง POS > รายการขาย</h2>
        <order-status-bar
          :labelGroups="labelGroups"
          v-model="datatable.selectedStatus"
        />
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col>
                <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>
              </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="datatable.selectedItems.length == 0"
                  icon
                ><v-icon>{{ mdiPrinter }}</v-icon></v-btn>
              </template>
              <v-list>

                <v-list-item
                  @click="printMultiple($PRINT_TYPE.ABB)"
                >
                  <v-list-item-title>ใบกำกับภาษีอย่างย่อ</v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="printMultiple($PRINT_TYPE.ABBA4)"
                >
                  <v-list-item-title>ใบกำกับภาษีอย่างย่อ A4</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
            class="ml-2"
            @click="exportExcel"
            :disabled="datatable.selectedItems.length == 0"
            icon
            ><v-icon>{{ mdiFileExcel }}</v-icon></v-btn>
            <v-btn
            color="primary"
             class="ml-auto"
             @click="showPOSCreator"
             v-if="device && device.getPermissionByAdminId($store.getters['auth/GET_USER_ID']) >= 2"
             >สร้างรายการขาย</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/pos/' + $route.params.device_id + '/list'"
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
            :mobile-breakpoint="0"
            class="fixed-column-table"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template
            v-slot:item.orderNumber="{ item }"
          >
            <router-link
            :to="{ name: 'posorder_create', params: { posorder_id: item.id, device_id: item.posdevice_id } }"
            >{{ item.orderNumber }}</router-link>
          </template>

          <template
            v-slot:item.created_at="{ item }"
          >
            {{ item.created_at | timestampToText }}
          </template>

          <template
            v-slot:item.creator="{ item }"
          >
            {{ item.creator ? item.creator.name + " " + item.creator.lastname : 'ระบบ' }}
          </template>

          <template
            v-slot:item.trd="{ item }"
          >
            <router-link
            v-if="item.trd"
            :to="{ name: 'trd_create', params: { trd_id: item.trd.id } }"
            >{{ item.trd.trdNumber }}</router-link>
          </template>

          <template
            v-slot:item.payby="{ item }"
          >
            <div v-if="!!item.payby">
              {{ item.payby.name }} ({{ item.payby && item.payby.type == 'transfer' ? "โอน" : (item.payby.type == 'credit_full' ? "เครดิตเต็ม" : "เครดิผ่อน") }})
            </div>
            <div v-else>
              เงินสด
            </div>
          </template>

          <template
            v-slot:item.value="{ item }"
          >
            {{ item.value | formatNumber(item.value, 2) }}
          </template>

          <template
            v-slot:item.status="{ item }"
          >
            <v-chip
              :color="item.status | orderStatusToColor"
              label
            >
              {{ item.status | orderStatusToText }}
            </v-chip>
          </template>

          <template
            v-slot:item.actions="{ item }"
          >
            <td class="fixed-column">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon>{{ mdiMenu }}</v-icon>
                  </v-btn>
                </template>
                <v-list>

                  <v-divider></v-divider>

                  <v-list-item @click="printSingle($PRINT_TYPE.ABB, item.id)">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบกำกับภาษีอย่างย่อ</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="printSingle($PRINT_TYPE.ABBA4, item.id)">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">{{ !!item.vat ? 'ใบกำกับภาษีอย่างย่อ A4' : 'ใบเสร็จ/ใบส่งของ'}}</v-list-item-title>
                  </v-list-item>

                  <v-list-item
                    v-if="!!item.trd"
                    @click="$router.push({ name: 'trd', query: { trdId: item.trd.id } })">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ใบกำกับภาษีแบบเต็ม</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                  v-else
                  @click="$router.push({ name: 'trd_create', params: { trd_id: 'create' }, query: { order_id: item.id, orderNumber: item.orderNumber, order_type: 'posorder' } })">
                    <v-list-item-icon>
                      <v-icon>{{mdiPrinter}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ออกใบกำกับภาษีแบบเต็ม</v-list-item-title>
                  </v-list-item>

                  <v-divider v-if="item.status != 'draft'"></v-divider>

                  <v-list-item @click="$router.push({name: 'posorder_create', params: { posorder_id: item.id, device_id: item.posdevice_id  }})">
                    <v-list-item-icon>
                      <v-icon>
                        {{ mdiEyeOutline }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="ml-2">ดู</v-list-item-title>
                  </v-list-item>

                  <template v-if="device && device.getPermissionByAdminId($store.getters['auth/GET_USER_ID']) >= 3">
                    <v-divider></v-divider>

                    <v-list-item @click="cancelOrder(item.id, item.orderNumber)">
                      <v-list-item-icon>
                        <v-icon>
                        {{ mdiDelete }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-2">ยกเลิก</v-list-item-title>
                    </v-list-item>
                  </template>

                </v-list>
              </v-menu>
            </td>
          </template>
          </delay-datatable>
        </v-card>
        <v-dialog
        v-model="posModal"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        style="max-height: 100vh;"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="posModal = false"
                    >
                        <v-icon>{{ mdiClose }}</v-icon>
                    </v-btn>
                    <v-toolbar-title>POS</v-toolbar-title>
                </v-toolbar>

                <POSCreator
                    :deviceId="$route.params.device_id"
                    @done="doneEventFromPOSCreator"
                ></POSCreator>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { prepareAndPrint } from '@/helpers/printing.js'
import { POSOrder } from '@/classes/pos/POSOrder'
import { Device } from '@/classes/pos/device'
import ProductImage from '@/components/ProductImage.vue'
import { asyncDelete, asyncGet } from '@/helpers/asyncAxios'
import errorParser from '@/plugins/errorparser'
import {
  mdiCashCheck,
  mdiCheck,
  mdiClose,
  mdiCloudUpload,
  mdiDelete,
  mdiEyeOutline,
  mdiFileExcel,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMenu,
  mdiPlaylistEdit,
  mdiPrinter,
  mdiTruckDeliveryOutline,
} from '@mdi/js'
import * as XLSX from 'xlsx'
import OrderStatusBar from './components/OrderStatusBar.vue'
import POSCreator from './components/POSCreator.vue'
export default {
  data() {
    return {
      device: null,
      posModal: false,
      labelGroups: [
        [
          {
            id: 'all',
            text: 'ทั้งหมด',
            counter: 0,
            color: '#D64935',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'sold',
            text: 'ขาย',
            counter: 0,
            color: '#6CCCA3',
            textColor: 'white',
          },
        ],
        [
          {
            id: 'cancelled',
            text: 'ยกเลิก',
            counter: 0,
            color: '#5B6573',
            textColor: 'white',
          },
        ],
      ],
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['created_at'],
          sortDesc: [true],
        },
        selectedStatus: 'all',
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'เลขที่บิล',
            value: 'orderNumber',
          },
          {
            text: 'วันที่สร้าง',
            value: 'created_at',
          },
          {
            text: 'ผู้สร้าง',
            value: 'creator',
            sortable: false,
            width: '150',
          },
          {
            text: 'มูลค่ารวม',
            value: 'value',
            width: '125',
          },
          {
            text: 'สถานะ',
            value: 'status',
          },
          {
            text: 'ช่องทางการชำระ',
            value: 'payby',
            sortable: false,
            width: '200',
          },
          {
            text: 'หมายเหตุ',
            value: 'note',
            width: '200',
          },
          {
            text: 'ใบกำกับภาษีแบบเต็ม',
            value: 'trd',
            sortable: false,
            width: '200',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
            class: 'fixed-column',
          },
        ],
      },
      mdiEyeOutline,
      mdiPlaylistEdit,
      mdiMenu,
      mdiLinkVariant,
      mdiCloudUpload,
      mdiInformationOutline,
      mdiTruckDeliveryOutline,
      mdiCheck,
      mdiDelete,
      mdiPrinter,
      mdiCashCheck,
      mdiClose,
      mdiFileExcel,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        selectedStatus: this.datatable.selectedStatus,
        search: this.datatable.search,
      }
    },
  },
  async created() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    try {
      this.queryCount()
      const response = await asyncGet('/pos/' + this.$route.params.device_id)

      this.device = new Device(response)
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', errorParser(error))
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    exportExcel() {
      const headers = this.datatable.headers.filter(item => item.value != 'actions').map(header => header.value)
      const headersText = this.datatable.headers.filter(item => item.value != 'actions').map(header => header.text)

      const data = this.datatable.selectedItems.map(item => {
        return headers.map(header => {
          if (!!item[header]) {
            if (header == 'created_at' || header == 'updated_at' || header == 'paid_at')
              return this.$options.filters.timestampToText(item[header])

            if (header == 'status') return this.$options.filters.orderStatusToText(item[header])

            if (header == 'creator' || header == 'updater') return item[header].name + ' ' + item[header].lastname

            if (header == 'customer') return item['sns_name'] + '\n\r' + item[header].name + '\n\r' + item[header].tel

            if (header == 'address')
              return item.address + ' ' + item.county + ' ' + item.city + ' ' + item.province + ' ' + item.postcode

            if (header == 'payby' && item.payby) {
              return (
                item.payby.name +
                '(' +
                (item.payby.type == 'transfer'
                  ? 'โอน'
                  : item.payby.type == 'credit_full'
                  ? 'เครดิตเต็ม'
                  : 'เครดิผ่อน') +
                ')'
              )
            }

            if (!!item[header].name) return item[header].name

            return item[header]
          }

          if (header == 'payby') return 'เงินสด'

          if (item[header] == 0) return 0

          return ''
        })
      })

      const arrayData = [headersText, ...data]

      const dataWS = XLSX.utils.aoa_to_sheet(arrayData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'export_posorders.xlsx')
    },
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async queryCount() {
      const device_id = this.$route.params.device_id
      try {
        const { total, sold, cancelled } = await asyncGet('/pos/' + device_id + '/count')

        this.labelGroups = [
          [
            {
              id: 'all',
              text: 'ทั้งหมด',
              counter: total,
              color: '#D64935',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'sold',
              text: 'ขาย',
              counter: sold,
              color: '#6CCCA3',
              textColor: 'white',
            },
          ],
          [
            {
              id: 'cancelled',
              text: 'ยกเลิก',
              counter: cancelled,
              color: '#5B6573',
              textColor: 'white',
            },
          ],
        ]
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
    },
    async cancelOrder(id, orderNumber) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยกเลิก ?',
        body:
          'คุณต้องการยกเลิกบิลหมายเลข "' +
          orderNumber +
          '" ใช่หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถแก้ไขสถานะกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      this.sendingData = true
      try {
        await asyncDelete('/pos', {
          items: [id],
        })

        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.sendingData = false
    },
    async printMultiple($type) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      const itemIds = this.datatable.selectedItems.map(item => item.id)

      try {
        const { company, data } = await asyncGet('/pos/' + $type, { items: itemIds })

        const orders = data.map(item => {
          const order = new POSOrder(item)

          return order
        })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: orders,
          company: company,
        })

        prepareAndPrint()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async printSingle($type, id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        const { company, data } = await asyncGet('/pos/' + $type, { items: [id] })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => {
            const order = new POSOrder(item)

            return order
          }),
          company: company,
        })

        prepareAndPrint()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    showPOSCreator() {
      this.posModal = true
    },
    doneEventFromPOSCreator() {
      this.$refs.mainDatatable.reload()
    },
  },
  components: {
    ProductImage,
    OrderStatusBar,
    POSCreator,
  },
}
</script>
<style scoped>
.fixed-column-table::v-deep .v-data-table th.fixed-column {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}

.fixed-column-table tbody tr td.fixed-column {
  position: sticky;
  right: 0;
  background: white;
  z-index: 1;
}
</style>
